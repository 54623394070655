<template>
	<v-content >
		<div id="core-view">
			<v-fade-transition mode="out-in">
				<nuxt></nuxt>
			</v-fade-transition>
		</div>
	</v-content>
</template>

<script>
export default {
	name: 'CoreView',

	metaInfo() {
		return {
			title: 'DATAPOP',
		};
	},
};
</script>
<style scoped>
.v-application {
  background-color: #138dd3;
}
</style>